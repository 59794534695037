/*! JS par Les Affranchis */
(function ( $ ) {

	affUtils = {
		isElementInView: function (element, fullyInView) {
			var pageTop = $(window).scrollTop();
			var pageBottom = pageTop + $(window).height();
			var elementTop = $(element).offset().top;
			var elementBottom = elementTop + $(element).height();

			if (fullyInView === true) {
				return ((pageTop < elementTop) && (pageBottom > elementBottom));
			} else {
				return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
			}
		}
	};


	affTogglePromoteInfo = {
		init : function () {
			var _this = affTogglePromoteInfo;

			_this.$sectionPromouvoir = $( '.promote_forfaits' );

			if ( _this.$sectionPromouvoir.length < 1 ) {
				return;
			}

			_this.bindEvents();

		},

		bindEvents : function () {
			var _this = affTogglePromoteInfo;

			_this.$sectionPromouvoir.on( 'click.affToggleInfo', '.cell-more-info button', function ( e ) {

				var $this        = $( e.currentTarget ),
					$section     = $this.parents( '.body-row' ),
					$infoSection = $section.find( '.more-info' );

				$infoSection.slideToggle( 'fast' );
				$section.toggleClass( 'open-info' );

				if ( $section.hasClass('open-info') && !affUtils.isElementInView( $infoSection[0], true ) ) {
					$( 'html, body' ).animate( {
						scrollTop : $section.offset().top
					}, 300 );
				}

			} );

		}
	};


	// doc.ready
	$( function () {
		affTogglePromoteInfo.init();
	} );
})( jQuery );